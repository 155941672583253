@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
/* Additional CSS styles using the Google Font */
*:focus {
    outline: none !important;
    border: 0px !important;
}

body * {
  font-family: "Inter", sans-serif;
}

.App {
  text-align: center;
  border-radius: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#mainWrapper {
  background: #f0f0f0;
  z-index: 2;
  position: relative;
  padding-bottom: 100px;
}

#mainWrapper.unauthenticated { margin-bottom: 0px; }
.minister-profile-image { width: 75px; }

.pagination { margin: 20px auto; width: fit-content;}
.paginationArea { margin-top: 80px; margin-bottom: 50px;}
.uppercase { text-transform: uppercase; }
.float-right { float:right; }
.required { color: red; }

#outer-container.public {
  padding: 0px;
  margin-left: 0px;  
}

#app-sidenav {
  background: #251D5C !important;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

#app-sidenav::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}


#sidenav-wrapper { float: none; }
#sidebarCloserTrigger { color: #fff; font-size: 30px; }
#sidebarCloserTrigger span {
  display: none;
}

#popup-root {
  z-index: 9999;
  position: absolute;
}

.public #content-wrapper { padding: 0px; }
.list-style-none { list-style: none; padding-left: 0px; margin-bottom: 0px; }
.relative-wrapper { position: relative; }

a { color: #677294; }

/* ************************************ GENERIC AREA ************************************ */

.strong { font-weight: 500; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.full-page {
  min-height: 100vh;
  background-color: #fff;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-size: cover;
}

.unauthenticated .full-page { background: transparent !important; }

.vertical-align {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.App.open-sidebar {
  margin-left: 240px !important;
  background: #251D5C !important;
  min-height: 100vh;
  padding: 0px;
}

.table-bordered thead td, .table-bordered thead th { vertical-align: middle !important; }
table * { font-size: 14px !important; }
.compact.table th, .compact.table td { padding: 10px 7px; }
.compact.table td input { padding: 7px 10px; border-radius: 0px; }
.columnSorter { cursor: pointer; }

.badge { font-size: 75% !important; padding:  5px 10px !important; }
.loaderWrapper svg { margin: auto; }
.loading-body { min-height: 80vh; }

table.plain * { background: #fff !important; }
table.plain strong { background: transparent !important; }
table.plain th { color: #000 !important; }

[class*=sidebar-dark-] {
  background-color: #121d28 !important;
}

p { font-size: .9rem !important; }

.brand-image.elevation-3 {
  border-radius: 0px !important;
  box-shadow: none !important;
}

.alert-primary, .card-primary:not(.card-outline)>.card-header, .small-box.bg-primary, .sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active, .page-item.active .page-link, .alert-primary, .btn-primary, .badge-primary {
  background-color: #007EB4 !important;
  border-color: #007EB4 !important;
}

.alert-success, .card-success:not(.card-outline)>.card-header, .small-box.bg-success, .badge-success, .btn-success {
  background-color: #65CEBE !important;
  border-color: #30b18a !important;
}

.alert-warning, .card-warning:not(.card-outline)>.card-header, .small-box.bg-warning, .badge-warning, .btn-warning {
  background-color: #fcc18c !important;
  border-color: #fcc18c !important;
}

.alert-danger, .card-danger:not(.card-outline)>.card-header, .small-box.bg-danger, .badge-danger, .btn-danger {
  background-color: #b3597c !important;
  border-color: #b3597c !important;
}

.table {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.table-danger, .table-danger>td, .table-danger>th {
  background-color:  #f5d4e1 !important;      
}

.table-striped tbody tr {
  background-color: #f7f5f8 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #e7e9f5 !important;
}

.table thead tr {
  background-color: #258bf5 !important;
  color:  #fff !important;
}

table th, table td { text-align: left; }
.table th, .table td { padding: 15px 10px; }

.card-header { padding: 15px; }
.btn { margin: 0px 2px; font-weight: 300; }

.table thead tr { border-top-left-radius: 10px; }
.table thead tr th {
  background: #3E2754;
  color: #fff;
  vertical-align: middle;
}

.table.light thead tr { background: transparent !important; }
.table.light thead tr th {
  background: transparent;
  color: #000;
  padding-top: 10px;
  font-weight: 500;
  border-bottom: 1px solid #947044;
}

.table.light tbody tr td {
  font-size: 12px !important;
}

.table.green thead tr th {
  background: #738478;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  background: #E7E1EB;
}

.table-striped.green>tbody>tr:nth-of-type(odd)>* {
  background: #E8F0EB !important;
}

.form-group { text-align: left; }
.form-group label { margin-bottom: 5px; margin-top: 10px; font-size: 14px; font-weight: bold; }
.form-control { font-weight: 300; padding: 10px 15px; }

#accomplishment_report_period {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-weight: 300;
  padding: 10px 15px;
}

#report_bank_remittance_spacer {
  margin-top: 30px;
}

#page-wrap {
  overflow-x: hidden;
  background: #f5f7fa;
}

/*.authenticated #page-wrap { overflow-y: scroll !important; }*/

tr.tri-span {
  height: 168px;
  font-weight: bold;
}

.page-header {
  margin-bottom: 30px;
  float: left;
  width: 100%;
  margin-top: 30px;
}

#headerSearchInput {
  background-repeat: no-repeat;
  padding-left: 45px;
  background-position-y: 10px;
  background-position-x: 10px;
}

.public #page-wrap { border-radius: 0px !important; }

.page-link {
  border: 0px;
  border-radius: 100%;
  padding: 6px;
  min-width: 38px;
  height: 38px;
  text-align: center;
  margin: 0px 15px;
  color: #000;
  cursor: pointer;
}

.page-link.active-page {
  background: #65CEBE !important;
}

.page-controls { margin-top: 15px; }

.module-filter-card { margin-bottom: 30px; margin-top: 10px; display: inline-block; width: 100%; }
.module-filter { text-align: left; }

.module-filter h5 {
  padding: 10px 25px;
  font-weight: bold;
  text-align: left;
}

.filter-column-instance { background: #eee; }
.filter-column-instance:nth-child(odd) {
  background: #f6f6f6;
}

.filter-column-instance .form-group { padding: 10px 5px; }
.module-filter-header { display: inline-block; }
.module-filter-buttons { text-align: left; }
.module-filter-buttons .btn { min-width: 120px; }

.red-highlight { color: red; }
.smallNote { text-left: left; float: left; margin-bottom: 10px; }
.capitalize { text-transform: capitalize; }

.member-form-dropdown--is-disabled {
  background-color: #f4f4f4;
  border-color: #ccc;
  color: #999;
}

.receipt-remark-wrapper {
  padding: 15px 15px 0px 15px;
  border-top: 1px solid #ddd;
}

.is-invalid-select .react-select-dropdown__control {
  border: 1px solid red;
}


/* Track */
::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1d1; /* Color of the handle */
  border-radius: 10px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8; /* Darker color when hovered */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Corner */
::-webkit-scrollbar-corner {
  background: transparent; /* Hide the corner */
}

.bar-loader { width: 80px; margin: auto; }

header { display: none; }
header.authenticated {
  padding: 30px 15px;
  background-color: #E1DED1;
  display: block;
}

header h6 {
  color: #000;
  font-size: 14px;
  text-align: left;
}

.slider-container .buttonWrapper img { width: 50px; }
.buttonWrapper {
  width: fit-content;
  padding: 10px;
  border-radius: 50%;
  margin: auto;
  background: #F0EEE8;
}

/* ##################################### ACCOUNT WIDGET ##################################### */

  #account-actions {
    position: absolute;
    right: 62px;
    top: 70px;
    font-weight: bold;
    color: #2E2668;
    cursor: pointer;
  }

  #account-action-wrapper {
    display: none;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
    width: 140px;
    margin-top: 15px;
    text-align: left;
    padding: 10px 25px;
    x-index: 1;
    position: absolute;
  }

  .open-widget #account-action-wrapper {
    display: block;
  }


/* ##################################### DASHBOARD ##################################### */

#dashboard-stats {
  margin-top: 25px;
  padding: 0px 30px;
}

#dashboard-header { padding-top: 30px; }
#dashboard-date {
  color: #979eb6;
  font-size: 20px;
  font-weight: 300;
}

.category-icon { display:inline-block; width: 40%; }
.category-details { display:inline-block; width: 60%; text-align: left; padding-left: 10px; }
.category-title { color: #947044; font-size: 10px; }
.category-count { color: #000; }
.category-icon img { margin-top: -25px; }
.stat-instance {
  border-radius: 20px;
  box-shadow: 0px 0px 20px #ccc;
  padding: 20px;
  margin-bottom: 25px;
}

.stat-instance.disabled {
  background-color: #ddd;
}

/* ##################################### DASHBOARD ##################################### */






/* ##################################### PAGE CONTROLS ##################################### */

  .page-search-icon {
    padding: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .section-limiter {
    width: 88%;
    margin: auto;
  }

  .authenticated .section-limiter { padding-bottom: 15px; }

  #searchWrapper { margin-top: -20px; }
  #searchWrapper .form-control {
    font-size: 14px;
    font-weight: 400;
    box-shadow: 0px 2px 10px #ddd;
    border-radius: 15px;
  }

  #dashboard-slider-container .groupButtonInstance .group-label {
    background: transparent !important;
  }

  #upcoming-schedule { margin-top: 20px; }
  .groupButtonInstance { margin-bottom: 25px; height: 125px; }
  .groupButtonInstance.active-group .buttonWrapper { background: #ccc; }

  .groupButtonInstance .group-label {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    height: 30px;
    width: 95px;
    margin: auto;
  }

  .subHeaderLabel {
    color: #7a8381;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
  }

  .sectionHeader {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  #continent_toggle .slick-slide {
    margin-right: 5px; /* Adjust the margin between slides */
  }

  #continent_toggle .slick-list {
    padding-left: 15px; /* Adjust padding on the right */
  }

/* ##################################### TABLE CONTROLS ##################################### */

  .action-items {
    padding: 5px 10px;
    color: #4E9AFF;
    font-size: 20px !important;
    cursor: pointer;
  }

/* ##################################### LOGIN STYLING ##################################### */

#login-page {
  background: linear-gradient(0deg, #ffffff, #e1ded1);
}

#pmcc-logo { margin-bottom: 50px; }

.social-media-login { width: 25px; }
#loginForm {
  width: 100%;
  margin: auto;
  background-color: transparent;
  color: #797979;
  border: none !important;
  padding: 0px 10px;
}

#loginForm .card-header {
  background: transparent !important;
  border: none;
  margin-bottom: 30px;
}

#loginForm .card-header h2 {
  font-weight: bold;
}

#loginForm input {
  color: #000;
  border: none;
  padding: 15px 25px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

#loginHeader {
  font-weight: 600 !important;
  font-size: 24px;
  color: #000;
}

#loginHeader small {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  margin-top: 15px;
  display: inline-block;
}

.login-rows { margin-bottom: 10px; }
#login-logo { height: 130px; margin-bottom: 30px; }

#forgotPasswordLink {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 0px;
}

#login-button {
  color: #000 !important;
  border-radius: 5px;
  padding: 17px 80px;
  font-weight: bold;
  background-color: #E1DED1 !important;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  border: none !important;
  margin-bottom: 50px;
  margin-top: 26px;
}

.loginFormIcon {
  background: #2E2668;
  color: #fff;
  border: none;
  border-radius: 0px;
  padding: 15px 20px;
}

.social-login {
  width: 100%;
  padding: 15px 0px;
  text-align: center;
  box-shadow: 0px 0px 10px #eee;
  background: #fff;
  border-radius: 5px;
}

#socialLoginWrapper {
  margin-bottom: 20px;
  margin-top: 30px;
}

.url-link {
  font-size: 13px;
  text-decoration: none;
  margin-top: 40px;
  display: inline-block;
}

/* ##################################### LOGIN STYLING ##################################### */





/* ##################################### POPUP/MODAL STYLING ##################################### */

#popup-content-wrapper {
  overflow-y: scroll;
  max-height: 700px;
  padding-right: 20px;
  margin-right: 15px;
}

.popup-form-row { margin-top: 20px; }
.popup-form-row input, .popup-form-row select { padding: 12px 25px; }
.popup-form-row label { font-weight: bold; }

.popup-content { padding: 40px !important; border-radius: 10px; width: 60% !important; }
.popup-content.dialog-confirm-content { width: 35% !important; }
.popup-body { margin-top: 30px; }
.popup-footer { margin-top: 30px; text-align: right; }
.popup-close {
  cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 20px;
    top: 25px;
    font-size: 35px;
    text-decoration: none;
    color: #555;
}

.popup-body .tab-list-wrapper { padding-left: 0px; }
.popup-body .tab-instance {
  display: inline-block !important;
  padding: 10px 30px;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  margin-right: 3px;
  cursor: pointer;
}
.popup-body .selected-tab {
  background: #007EB4;
  color: #fff;
  font-weight: bold;
}

.modal .btn {
  color: #000;
  border: none;
  font-size: 14px;
  padding: 10px 25px;
}

.btn-primary {
  background: #e1ded1 !important;
}

.btn-secondary {
  color: #fff !important;
}

/* ##################################### POPUP/MODAL STYLING ##################################### */




/* ##################################### CONTACT DETAILS ##################################### */

#add-contact {
  background: #F0EEE8;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  float: right;
  font-weight: 200;
  font-size: 30px;
  line-height: 1;
}

.contact-infromation-wrapper { position: relative; }

.contact-instance {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ccc;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.contact-image {
  width: 82px;
  height: 104px;
  background-size: cover !important;
  float:left ;
}

.locale-instance.contact-image {
  width: 62px;
  height: 84px;
  background-size: cover !important;
  float:left ;
}

.contact-information {
  padding-left: 15px;
  text-align: left;
  float: left;
}

.contact-appointment-wrapper {
  width: 100%;
  float:left;
}

.contact-appointment-wrapper { margin-top: 10px; }
.appointment-link { width: 100%; background-color: #e1ded1 !important; color: #000; border: none !important; padding: 10px; font-weight: 400; }

.contact-designation {
  font-size: 12px;
  background: #e1ded1 !important;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 25px;
  font-weight: 600;
}

.locale-instance.contact-designation {
  width:100%;
  font-size:12px !important;
  border-radius: 0px;
  margin-bottom: 15px;
}

.locale-instance .contact-information {
  padding-left: 0px;
  width: 100%;
}
.locale-instance.contact-infromation-wrapper {
  position: relative;
  width: 80%;
  padding-left: 25px;
  display: inline-block;
}

.typeLocale {
  margin-top: -3px;
  float: left;
}

.contact-name {
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
}

.locale-instance a { text-decoration: none; }
.locale-instance .contact-name {
  font-size: 15px !important;
}

.contact-area { font-size: 13px; color: #565656; }

.locale-instance .contact-name { margin-top: 0px; }
.locale-instance .contact-area {
  margin-top: 10px;
  border-top: 1px solid #ccc;
}

.locale-instance .contact-image {
  display: inline-block;
}

#searchWrapper.contacts-element {
  margin-top: 10px;
  margin-bottom: 25px;
}

.contact-call-to-action:first-child { padding-left: 0px !important; }
.contact-call-to-action {
  display: inline-block;
  padding: 15px 8px 0px;
}

.contact-area.contacts-instance {
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 5px;
}

.contact-information.contacts-instance {
  padding-left: 15px;
  text-align: left;
  float: left;
  width: 77%;
}

.contact-information.contacts-instance .contact-designation {
  width: 100%;
  border-radius: 0px;
}

.contact-information.contacts-instance .contact-locale {
  font-size: 12px;
}

.contact-call-to-action .action-trigger {
  background: #F0EEE8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  color: #777;
  position: relative;
}

.contact-call-to-action .action-trigger i {
  top: 12px;
  position: absolute;
  left: 14px;
}

.contacts-element .contact-image {
  width: 62px;
  height: 84px;
  background-size: cover !important;
  float: left;
}

.category-toggle-instance {
  padding: 0px 5px;
}

.island-toggle-instance.active-island, .continent-toggle-instance.active-continent .group-label {
  color: #ac9271;
  border-bottom: 2px solid #ac9271;
  border-radius: 0px;
}

.island-toggle-instance.active-island { padding-bottom: 10px; }

.group-label {
  font-size: 12px;
  font-size: 11px;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 25px;
  font-weight: 600;
  width: 100%;
}

.call-action-wrapper {
    position: absolute;
    right: 15px;
    top: 15px;
    background: #F0EEE8;
    padding: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

#contact-single-page .contact-image {
  float: none;
}

#contact-single-page .contact-designation { color: #797c7b; background: none !important; }
.action-icon {
  background: #F0EEE8;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

#contact-action-widgets {
  margin-top: 20px;
}

#contact-profile-information {
  background: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px -10px 20px #eee;
  margin-top: 40px;
  padding: 25px;
}

.profile-information-instance { width: 100%; text-align: left; }

.profile-information-instance {
  margin-top: 20px;
}

.profile-info-label {
  color: #797c7b;
  font-size: 15px;
  font-weight: 600;
}

.profile-info-value {
  font-size: 20px;
  font-weight: 600;
}

#profile-group-wrapper { text-align: left; margin-top: 30px; }
#profile-group-wrapper h6 { color: #797c7b; font-weight: 600; margin-bottom: 20px; }

#profile-group-wrapper .category-toggle-instance {
  display: inline-block;
}

#profile-group-wrapper .group-label {
  font-size: 12px;
  font-size: 11px;
  background: #e9e8e1 !important;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 10px;
  font-weight: 600;
  width: fit-content;
  display: inline-block;
  color: #777;
  margin-bottom: 15px;
}

/* ##################################### CONTACT DETAILS ##################################### */


.regularTabWrapper .tab-list-wrapper { text-align: left; padding-left: 0px; }
.regularTabWrapper .tab-instance { display: inline-block; }
#location-name-wrapper { margin-top: 20px; }

/* ##################################### COUNTRIES STYLING ##################################### */

#continent_toggle {
  padding-bottom: 9px;
  border-bottom: 1px solid #eee;
}

/*#continent_toggle .slick-slide.slick-active.slick-current {
  color: #ac9271;
  border-bottom: 2px solid #ac9271;
}*/

.continent-toggle-instance .group-label {
    background: transparent !important;
    font-size: 12px;
    font-size: 12px;
    display: inline-block;
    padding: 7px 17px;
}

#countries-wrapper {
  padding: 15px 10px;
  box-shadow: 0px 0px 20px #ddd;
  margin-top: 35px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.country-circle-flag {
  width: 30px;
  margin-right: 15px;
}

.table.light tbody tr td.locale-name, .table.light tbody tr td.country-name { font-size: 13px !important; }
.locale-name a, .country-name a { text-decoration: none; color: #000; }

/* ##################################### COUNTRIES STYLING ##################################### */

.state-province-name {
  color: #ac9271;
  border-bottom: 2px solid #ac9271;
  border-radius: 0px;
  padding: 0px 10px 10px 10px;
}

/* ##################################### LOCALE STYLING ##################################### */

#locale-stats-wrapper { margin-top: 40px; }

.main-stat {
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.main-stat-label {
  font-size: 12px;
  color: #000;
  display: inline-block;
  font-weight: 600;
}

.main-stat-value {
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
  color: #677294;
  font-weight: 600;
}

.male-stat-breakdown, .female-stat-breakdown {
  width: 100%;
  float: left;
  text-align: left;
  height: 18px;
}

.secondary-stat-label {
  text-align: left;
  display: inline-block;
  font-size: 12px;
  color: #000;
  font-weight: 600;
}

.secondary-stat-value {
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
  color: #677294;
  font-weight: 600;
}

/* ##################################### FOOTER STYLING ##################################### */

footer { position: fixed; width: 100%; bottom: 0; z-index: 9; }
#fixed-footer-navigation-wrapper a { text-decoration: none; }
#fixed-footer-navigation {
  background: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-top: 1px solid #d9d9d9;
  overflow: hidden;
  height: 80px;
  box-shadow: 0px 0px 15px #ccc;
}

.nav-instane-wrapper {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
}

.nav-instance-icon img { width: 35px; margin: auto; }
.nav-instance-label { font-size: 12px; margin-top: 5px; }

/* ################################# INVOICES AND TRANSACTIONS ################################# */

.tab-instance {
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  display: inline-block !important;
  padding: 10px 30px;
  border-radius: 5px !important;
  cursor: pointer;
  color: #000;
  background: #e6e6e6;
  margin-right: 10px;
}

.tab-instance.selected-tab {
  background: #007EB4;
  color: #fff;
  font-weight: bold;
}

.invoice-remittance-header { margin-bottom: 20px; }

#invoices-remittance-wrapper .react-tabs__tab-panel--selected {
  padding: 30px;
  background: #f6f6f6;
}

.invoice-remittance-form .form-group:first-child {
  margin-top: 0px;
}

.invoice-remittance-form .form-group {
  margin-top: 15px;
}

.invoice-remittance-form .form-group label { font-weight: bold; }
.invoice-remittance-form .form-group label small { color: red; }
.invoice-remittance-form .form-control { padding: 12px 25px; }

.invoice-remittance-form {
  padding: 0px 25px 0px 0px;
}

.invoice-remittance-form:nth-child(2) {
  margin-top: 30px;
}

#invoice-remittance-popup.popup-body {
  max-height: 600px;
  overflow-y: scroll;
}

#bank_account_selector {
  border-bottom: 1px solid #ddd;
  padding-bottom: 40px;
  margin-bottom: 20px;
}

#remittance-proof-fieldset {
  margin-top: 40px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.remittance-popup-label { font-weight: bold; font-size: 15px; }
.remittance-popup-value { margin-bottom: 20px; font-weight: 100; font-size: 18px; }
.accordion-trigger { background: #E8F0EB; }

.accordion-trigger[data-active=true] {
  background: #738478;
  color: #fff;
}

.mantine-Accordion-item[data-active=true] {
  background: #f6f6f6;
}

.accordion-item-instance.has-raw button { background: #b3597c !important; }
.remittance-popup-details-header { margin-top: 20px; font-weight: 400; }

.appended-child-remittance-receipt-remarks {
    margin-top: 25px;
    border-top: 1px solid #eee;
    padding-top: 15px;
    margin-bottom: 30px;
}

.Toastify__toast {
  padding: 15px;
  margin: 10px auto 10px;
  width: 95%;
  border-radius: 10px;
}

#loadingWrapper {
  width: 100%;
  padding: 20px;
}